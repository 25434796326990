<template>
  <div>
    <b-container fluid class="p-5">
      <b-tabs content-class="mt-3">
        <b-tab title="查看公告">
          <ScanNotices></ScanNotices>
        </b-tab>
        <b-tab title="我发布的">
          <PublishedNotice></PublishedNotice>
        </b-tab>
        <b-tab title="创建公告">
          <CreateNotice></CreateNotice>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import CreateNotice from "@/components/Notice/components/CreateNotice";
import PublishedNotice from "@/components/Notice/components/PublishedNotice";
import ScanNotices from "@/components/Notice/components/ScanNotices";

export default {
  name: "Notice",
  components: {ScanNotices, PublishedNotice, CreateNotice}
}
</script>

<style scoped>

</style>
