<template>
  <div>
    <b-row>
      <p>只显示未读：
        <el-switch
            v-model="unreadOnly"
            active-color="#13ce66"
            @change="getMyNotices"
        >
        </el-switch>
      </p>

      <b-col lg="12" class="my-1">
        <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0 w-50"
        >
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">清除</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
          <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-3">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
        :items="myNotices"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        stacked="md"
        show-empty
        small>

      <template #cell(content)="row">
        <b-button size="sm" @click="goDetails(row.item.id)">
          公告详情
        </b-button>
      </template>

    </b-table>
  </div>
</template>

<script>
import {showNoticesToMe} from "@/api";

export default {
  name: "ScanNotices",
  data() {
    return {
      unreadOnly: false,
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 200, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      myNotices: [],
      fields: [
        {key: 'id', label: '编号', sortable: true},
        {key: 'type', label: '类型', sortable: true},
        {key: 'urgency', label: '级别', sortable: true},
        {key: 'title', label: '标题', sortable: true},
        {key: 'publisherId', label: '发起人'},
        {key: 'publishTime', label: '发布时间', sortable: true},
        {key: 'content', label: '内容'}
      ]
    }
  },
  mounted() {
    this.getMyNotices()
  },
  methods: {
    getMyNotices() {
      showNoticesToMe(this.unreadOnly, 1, 50).then(res => {
        if (res.data.status) {
          this.myNotices = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    goDetails(id) {
      this.$router.push({name: 'NoticeDetails', params: {noticeId: id}})
    }
  }
}
</script>

<style scoped>

</style>
