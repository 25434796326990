<template>
  <div>
    <b-container>
      <b-card class="shadow m-3 p-3">
        <b-row>
          <b-col :md="4" class="border-right  px-3">
            <b-form-group
                class="my-3"
                id="input-group-1"
                label="公告类型"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.type"
                  type="text"
                  placeholder="Enter Type"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                class="my-3"
                id="input-group-2"
                label="紧急程度"
                label-for="input-2"
            >
              <b-form-input
                  id="input-2"
                  v-model="form.urgency"
                  type="text"
                  placeholder="Enter Urgency"
                  required
              ></b-form-input>
            </b-form-group>
            {{ form.date }}
            <b-form-group
                class="my-3"
                id="input-group-3"
                label="发布时间"
                label-for="input-3"
            >
              <el-date-picker
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="form.date"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
            </b-form-group>
            <b-form-group
                class="my-3"
                label="上传附件"
            >
              <input type="file" class="fileExport" @change="handleFileChange" ref="inputer1">
            </b-form-group>
            <b-form-group
                class="my-3"
                label="选择接收人"
            >
              <b-button v-b-toggle.sidebar-right class="w-25">选择</b-button>
              <b-avatar-group class="mt-3" size="60px" v-if="selected!==null">
                <b-avatar class="bg-secondary" v-for="s in selected" :key="s" value="s"></b-avatar>
              </b-avatar-group>
            </b-form-group>

          </b-col>
          <b-col :md="8" class="px-3">
            <b-form-group
                class="my-3 w-75"
                id="input-group-4"
                label="标题"
                label-for="input-4"
            >
              <b-form-input
                  id="input-4"
                  v-model="form.title"
                  type="text"
                  placeholder="Enter Title"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                class="my-3 w-75"
                id="input-group-5"
                label="关键字"
                label-for="input-5"
            >
              <b-form-input
                  id="input-5"
                  v-model="form.keyword"
                  type="text"
                  placeholder="Enter Keyword"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                class="my-3"
                id="input-group-3"
                label="公告内容"
                label-for="input-1"
                description="从此处编辑公告内容."
            >
              <b-form-textarea
                  style="min-height: 280px"
                  id="textarea"
                  v-model="form.content"
                  placeholder="Enter something..."
                  rows="6"
                  max-rows="12"
              ></b-form-textarea>
            </b-form-group>
            <div style="text-align: center">
              <b-button class="w-50 my-3" variant="primary" @click="createNotice">发布</b-button>
            </div>
          </b-col>
        </b-row>

      </b-card>
    </b-container>
    <b-sidebar id="sidebar-right" title="选择接收者" width="600px" right shadow>
      <div class="px-3 py-2">
        <b-form>
          <small>根据Id查找</small>
          <b-row class="px-3">
            <b-form-input class="w-75" v-model="searchId" placeholder="输入权限ID"></b-form-input>
            <b-button class="w-25">添加至列表</b-button>
          </b-row>

          <el-select class="mt-3 w-100" multiple v-model="selected" placeholder="请选择">
            <el-option
                v-for="item in staffs"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>

          <b-button size="sm" @click="selectAllRows">Select all</b-button>
          <b-button size="sm" @click="clearSelected" class="mx-3">Clear selected</b-button>
          <b-table :items="staffs" :fields="fields"
                   :select-mode="selectMode"
                   ref="selectableTable"
                   selectable
                   @row-selected="onRowSelected"
                   striped responsive="sm">
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
        </b-form>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {addNoticeAudience, createNotice, createNoticeAttachment, showStaffsByLikeName} from "@/api";
import cos from '@/main'

export default {
  name: "CreateNotice",
  data() {
    return {
      searchId: '',
      form: {
        type: '',
        urgency: '',
        date: '',
        title: '',
        keyword: '',
        content: ''
      },
      audiences: [],
      selected: [],
      staffs: [],
      fields: [{key: 'selected', label: '选择'}, {key: 'id', label: '工号'}, {key: 'name', label: '姓名'}],
    }
  },
  mounted() {
    this.searchStaffs()
  },
  methods: {
    onRowSelected(items) {
      let idList = []
      items.forEach(i => {
        idList.push(i.id)
      })
      this.selected = idList
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    searchStaffs() {
      showStaffsByLikeName(this.searchId, 1, 100).then(res => {
        if (res.data.status) {
          this.staffs = res.data.data.content
        }
      })
    },
    createNotice() {
      createNotice(this.form.title, this.form.keyword, this.form.type, this.form.urgency, this.form.date, this.form.content).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          const notice = res.data.data
          this.putObj(notice.id)
          this.selected.forEach(s => {
            addNoticeAudience(notice.id,s).then(res => {
              if (res.data.status === false) {
                this.$message.error(res.data.message)
              }
            })
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handleFileChange(e) {
      const inputDOM = this.$refs.inputer1
      this.file = inputDOM.files[0]
      this.formData = new FormData()
      this.formData.append('file', this.file)
      this.formData.append('taskId', this.currentTask.id)
    },
    putObj(id) {
      const fileName = this.file.name
      const keyID = new Date().getTime() + '-' + fileName
      cos.putObject({
        Bucket: 'cmcc-authority-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: this.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
        }
      }, function (err, data) {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          createNoticeAttachment(id, fileName, fileName.substring(fileName.lastIndexOf('.') + 1), 'https://' + location).then(res => {
            if (res.data.status) {
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      })
    },
  }
}
</script>

<style>
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
</style>
